import "./style.scss";
import React from "react";
export declare type AddresInformation = {
    address: string;
    city: string;
    zipcode: string;
    state: string;
};
export interface LocationSearchInputProps {
    handleSelect?: (address: AddresInformation) => void;
    handleChange?: (address: string) => void;
    address: string;
    size?: "lg" | "md" | "sm";
    width?: string | number;
    height?: string | number;
}
export default class LocationSearchInput extends React.PureComponent<LocationSearchInputProps> {
    constructor(props: any);
    state: {
        address: string;
    };
    componentDidUpdate: () => void;
    handleChange: (address: string) => void;
    handleSelect: (address: string) => void;
    render(): JSX.Element;
}

export enum SupportedCountriesCode {
    AE = "AE",
    US = "US",
    CA = "CA"
}
