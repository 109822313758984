import "./style.scss";

import DynamicTable, { DynamicTableStateless } from "@atlaskit/dynamic-table";
import { Box, Text } from "../_components";
import { AlternativeDropDowns, Pagination } from "@samedaycustom/core-ui";
import { formatInTimeZone } from "date-fns-tz";
import moment from "moment";
import React, { useMemo } from "react";
import Style from "style-it";
import { ReactComponent as ArrowDown } from "@samedaycustom/assets/images/arrow-down.svg";


const defaultRage = [
  { label: "50", value: "50" },
  { label: "100", value: "100" },
  { label: "150", value: "150" },
  { label: "200", value: "200" },
]

export const RowDueDate: React.FC<{
  date: any;
  isCritical?: boolean;
  isFulfiled?: boolean;
  tzone?: string;
}> = ({ date, isCritical, isFulfiled, tzone }) => {
  const isDueDateOverdue = moment(date).isBefore(moment(), "minute");
  const isBetweenTodayAndTomorrow = moment(date).isBetween(
    moment(),
    moment().add(1, "day"),
    "day",
    "[]"
  );
  const isToday = moment(date).isSame(moment(), "day");

  const dateBetweenTodayAndTomorrow = moment(date).calendar(null, {
    sameDay: "[Today in]",
    nextDay: "[Tomorrow at]",
  });

  const dateLeftInOrders = moment(new Date()).diff(date, "milliseconds");
  const dueDateLeft = moment.duration(dateLeftInOrders, "milliseconds").humanize(true);

  const dateInDate = tzone
    ? formatInTimeZone(date, tzone, "dd MMM, yyyy")
    : moment(date).format("DD MMM, YYYY");
  const dateInTime = tzone
    ? formatInTimeZone(date, tzone, "hh:mm a")
    : moment(date).format("hh:mm A");

  const textStyle = React.useMemo(() => {
    if (isCritical) return { color: "#EF4E4E", fontWeight: 600 };
    return { color: "#102A43", fontWeight: 400 };
  }, [isCritical]);

  const useRelativeTime = false;

  return (
    <Box className="row-color-container" fontFamily="Inter">
      {isDueDateOverdue && !isFulfiled ? (
        <Text color="#EF4E4E" fontWeight={600}>
          {dueDateLeft} overdue
        </Text>
      ) : useRelativeTime && isBetweenTodayAndTomorrow && !isFulfiled ? (
        <Text {...textStyle}>
          {dateBetweenTodayAndTomorrow} {isToday ? dueDateLeft : dateInTime}
        </Text>
      ) : (
        <Box fontWeight="normal">
          <Text>{dateInDate}</Text>
          <Text>{dateInTime}</Text>
        </Box>
      )}
    </Box>
  );
};

export interface TableProps {
  headerBackgroundColor?: string;
  setPage?: (e: any) => void;
  rowsPerPage?: number;
  thPadding?: string;
  stateless?: boolean;
  rows?: any;
  page?: number;
  tdPadding?: string;
  tdPaddingBottom?: string;
  tdPaddingTop?: string;
  tdBorderBottom?: string;
  rowClickable?: boolean;
  showRecordsRange?: boolean;
  customRange?: { label: string; value: string }[];
  currentRecordRange?: string;
  handleRecordRange?: (range: string) => void;
}
export const Table = React.memo((props: any) => {
  const {
    defaultIndicator,
    numRows,
    // onClickPrevPage: propOnClickPrevPage,
    // onClickNextPage: propOnClickNextPage,
    // onClickLastPage: propOnClickLastPage,
    // onClickFirstPage: propOnClickFirstPage,
    setPage,
    rowsPerPage,
    thPadding,
    stateless,
    rows,
    paginationPage,
    thHeight,
    tdPadding,
    tdPaddingBottom,
    tdPaddingTop,
    tdBorderBottom,
    hidePagination,
    rowClickable,
    offHover,
    tdthAlign,
    isFirstPage,
    isLastPage,
    pageStart,
    pageEnd,
    fixedColumn = 0,
    fixedColumnEnd = 0,
    showRecordsRange = false,
    customRange,
    currentRecordRange,
    handleRecordRange,
  } = props;

  const Table = stateless ? DynamicTableStateless : DynamicTable;

  // Function to calculate cumulative width of fixed columns
  function getCumulativeColumnWidth(columns) {
    let cumulativeWidth = 0;
    for (let i = 1; i <= columns; i++) {
      cumulativeWidth += getPreviousColumnWidth(i);
    }
    return cumulativeWidth;
  }

  // Function to get the width of a specific column
  function getPreviousColumnWidth(columnIndex) {
    const tableElement = document.querySelector('.table-container table');
    if (tableElement) {
      const allColumns = tableElement.querySelectorAll('th, td');
      const columnElement: any = allColumns[columnIndex - 1];
      if (columnElement) {
        return columnElement.offsetWidth;
      }
    }
    return 0;
  }

  // Generate the styles for fixed columns based on the fixedColumn value
  let fixedColumnStyles = '';

  // Apply styles for fixed left columns
  for (let i = 1; i <= fixedColumn; i++) {
    const cumulativeWidth = getCumulativeColumnWidth(i);

    // Apply styles to fixed left headers
    document.styleSheets[0].insertRule(`
      .table-container th:nth-child(${i}) {
        position: sticky;
        left: ${cumulativeWidth}px;
        z-index: 99;
        background-color: #F0F4F8;
        padding-left: 20px;
      }
    `, 0);

    // Apply styles to fixed left data cells
    document.styleSheets[0].insertRule(`
      .table-container td:nth-child(${i}) {
        position: sticky;
        left: ${cumulativeWidth}px;
        z-index: 999;
        background-color: #fff;
      }
    `, 0);
  }

  const totalColumns = useMemo(() => rows?.[0]?.cells?.length || 0, [rows]);

  // Apply styles for fixed right columns
  for (let i = 1; i <= fixedColumnEnd; i++) {
    const cumulativeWidth = getCumulativeColumnWidth(i);
    document.styleSheets[0].insertRule(`
      .table-container th:nth-child(${totalColumns - i + 1}) {
        position: sticky;
        right: ${cumulativeWidth}px;
        background-color: #F0F4F8;
      }
    `, 0);

    document.styleSheets[0].insertRule(`
      .table-container td:nth-child(${totalColumns - i + 1}) {
        position: sticky;
        right: ${cumulativeWidth}px;
        background-color: #fff;
      }
    `, 0);
  }

  return Style.it(
    `
        .table-container thead, th{
            background: ${props.headerBackgroundColor || "#F0F4F8"};
            padding: ${thPadding || "15px 8px 15px"};
            border-bottom: none !important;
            font-family: Inter !important;
            font-weight: 500 !important;
            font-size: ${thHeight || "13px"} !important;
        }

        .table-container td{
            border-bottom: ${tdBorderBottom};
            padding-bottom: ${tdPaddingBottom || "0.8rem"};
			padding-top:  ${tdPaddingTop || "0.8rem"};
			padding: ${tdPadding};

            overflow: visible !important;
        }
         .table-container tr{
            cursor: ${rowClickable && "pointer"}
         }
         .table-container tr:hover{
             background: ${(offHover && "unset") || "#F1F4F84F"};
         }
         .table-container th,td,tr{
            font-family: Inter;
            font-weight: normal;
         }
         .table-container th,td{
             font-size: 1rem;
             text-align: ${tdthAlign || "unset"}
         }

         .table-container [page]{
            display: ${!defaultIndicator && "none"}
         }
         .table-container [aria-label="Prev"]{
             display: none;
         }
         .table-container [aria-label="Next"]{
             display: none;
         }

         .table-container .pagination-controller .pagination-controls p{
            font-size: 1rem;
            font-family: Inter;
         }

         // Fixed left column styles 
         .table-container td:nth-child(${fixedColumn}),
         .table-container th:nth-child(${fixedColumn}) {
          box-shadow: inset -1px 0 0 0 #D9E2EC;
        }

         .table-container td:nth-child(${fixedColumn + 1}),
         .table-container th:nth-child(${fixedColumn + 1}) {
          padding-left: 20px;
        }

        // Fixed right column styles
        .table-container th:nth-child(${totalColumns - fixedColumnEnd + 1}) {
          z-index: 99;
          box-shadow: inset 1px 0 0 0 #D9E2EC;
        }

        .table-container td:nth-child(${totalColumns - fixedColumnEnd + 1}) {
          z-index: 999;
          box-shadow: inset 1px 0 0 0 #D9E2EC;
        }

         ${fixedColumnStyles}
        }  
        `,
    <Box className="table-container">
      <div className={fixedColumn ? "alt-table-wrapper-styles" : ""}>
        <div className={fixedColumn ? "alt-table-overflow-styles" : ""}>
          <Table rows={rows} isLoading={props?.isLoading} {...props} />
        </div>
      </div>
      {!hidePagination /*&& numRows > rowsPerPage*/ && (
        <Box
          d="flex"
          flexDirection="row"
          flexWrap="wrap"
          flexGrow={5}
          width="100%"
          alignItems="top"
          justifyContent="space-between"
          className="pagination-controller"
          paddingBottom="2rem"
        >
          {showRecordsRange && (<Box display="flex" gap="8px" alignItems="center">
            <Text>Show</Text>
            <AlternativeDropDowns
              value={currentRecordRange}
              position="top left"
              listItems={customRange || defaultRage}
              onClick={(e: any) => {
                handleRecordRange(e)
              }}
              className="records-range"
              optionClassName="record-range-option"
              icon={<ArrowDown fill="#07121D" />}
              containerStyle={{ marginLeft: "-25px" }}
              leftContentStyle={{ color: "#243B53", fontWeight: 500, lineHeight: "20px", fontSize: "14px" }}
            />
            <Text>records</Text>
          </Box>)}

          <Box width={`${!showRecordsRange ? "100%" : ""}`}>
            <Pagination
              currentNumber={paginationPage}
              stepNumber={rowsPerPage}
              maxLength={numRows}
              changeFunc={setPage}
              isFirstPage={isFirstPage}
              isLastPage={isLastPage}
              pageStart={pageStart}
              pageEnd={pageEnd}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
});

export * from "./DynamicFoldTable";
export { default as DynamiFoldTable } from "./DynamicFoldTable";
export { default as FoldableTable } from "./FoldableTable";
export { default as ListPage } from "./ListPage";
export { default as ListTable } from "./ListTable";
export { default as MiniTable } from "./MiniTable";
export { default as TablePagination } from "./TablePagination";
