import "./style.scss";

import { Box } from "../../_components";
import { NavDropDown as NavDropdown, RowTag } from "@samedaycustom/core-ui";
import { usePrevious } from "@samedaycustom/hooks";
import { concat } from "lodash";
import millify from "millify";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import Style from "style-it";
import { useDebouncedCallback } from "use-debounce/lib";
import { isNumber } from "util";

const HorizontalSliderNavigation = ({
  children,
  options = [],
  match,
  history,
  target,
  setTarget,
  mini,
  showSeperator = true,
  bottomSeperatorHeight,
  sliderSize = "large",
  fullBottomSeperatorWidth,
  fullBottomColor,
  style,
  bottomSeperatorTop,
  fontSize,
  navItemStyle,
  leftContent,
  optionColor,
  isPriorited,
  optionOffset,
  padding,
  ...props
}: any) => {
  const containerRef = createRef();

  const sliderRef = createRef<HTMLBoxElement>();
  const [xpos, setXpos] = useState(0);
  const [width, setWidth] = useState("0px");
  const [hiddenNavItems, setHiddenNavItems] = useState([]);
  const [breaks, setbreaks] = useState([]);
  const [optionList, setOptions] = useState([]);

  const prevOptions = usePrevious(options);
  const refList = React.useMemo(() => options?.map(option => createRef()), [
    options
  ]);
  const _setOption = options => {
    /**
     *
     *
     * filter all static items and move them to the end of the options
     */
    options = concat(
      options?.filter(o => !o?.isStatic),
      options?.filter(o => o?.isStatic)
    );
    setOptions(options);
  };

  useMemo(() => {
    if (prevOptions !== options) {
      _setOption(options);
    }
  }, [options]);

  /**
   *
   *
   * priority navigation logic
   */
  const [updateResponsiveValues] = useDebouncedCallback(
    // function
    (parentwidth, totalNavitemWidth) => {
      // const options = optionList.filter((o) => !o?.isStatic); // filter out static options that wont be added to the navigation option
      const options = optionList;
      const containerWidth = parentwidth;

      // if all navigatoin item width is greater than or equal to the container width 
      // then remove last option gradually

      if (totalNavitemWidth >= containerWidth) {
        const popIndex = options?.length - 1;
        const poppedItem = options?.pop();
        const hiddenItemsIndex =
          hiddenNavItems?.findIndex(
            (e: any) => e?.item?.id === poppedItem?.id
          ) === -1;

        // if item popped from options and popped item is not hidden, and the options length is greater than or equal to option offset provided
        // then add popped item to hiddenItems
        if (poppedItem && hiddenItemsIndex && options?.length >= optionOffset) {
          setHiddenNavItems(prev =>
            prev?.concat({
              label: !poppedItem?.isStatic
                ? poppedItem?.text
                : String(poppedItem?.staticText)?.toLowerCase(),

              value: !poppedItem?.isStatic
                ? poppedItem?.text
                : String(poppedItem?.staticText)?.toLowerCase(),

              item: poppedItem,
              onClick: (e: any) =>
                !poppedItem?.isStatic
                  ? optionFunc(e, popIndex)
                  : poppedItem?.onClick()
            })
          );
          setbreaks(prev => prev?.concat(totalNavitemWidth)); // set break point of when item has been hidden
          _setOption(options); // then set mutated options
        }
      } else if (
        hiddenNavItems?.length > 0 &&
        containerWidth > totalNavitemWidth + breaks[breaks?.length - 1] - 250
      ) {
        const lastitem = hiddenNavItems?.pop();
        _setOption([...optionList, lastitem?.item]);
      }
    },
    // delay in ms
    200
  );

  function updateSize() {
    const parentWidth = document.getElementById("horizontalNav")?.offsetWidth;
    const widthd = document.getElementById("navigation")?.offsetWidth;
    const width = Math.floor(widthd);

    if (isPriorited) updateResponsiveValues(Math.floor(parentWidth), width);
  }

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
      setHiddenNavItems([]);
    };
  }, []);
  /******* */

  useEffect(() => {
    if (
      typeof sliderRef !== "undefined" &&
      sliderRef?.current &&
      typeof refList !== "undefined" &&
      typeof refList[target] !== "undefined"
    )
      sliderRef.current.style.width = `${refList[target]?.current?.clientWidth}px`;
  }, [sliderRef, width, target, refList]);

  const optionFunc = (e, idx) => setTarget(idx);

  useEffect(() => {
    if (
      typeof refList !== "undefined" &&
      typeof refList[target] !== "undefined"
    ) {
      setXpos(
        refList[target]?.current?.offsetLeft - containerRef?.current?.offsetLeft
      );

      // @ts-ignore
      setWidth(refList?.[target]?.current?.clientWidth);
    }
  }, [containerRef, refList, target]);

  const dynamicStyles = React.useMemo(
    () => ({
      left: `${xpos}px`,
      width: `${width}px`
    }),
    [xpos, width]
  );

  useEffect(() => {
    if (
      typeof refList !== "undefined" &&
      optionList?.length > 0 &&
      typeof refList[0] !== "undefined"
    ) {
      setWidth(`${refList[0]?.current?.clientWidth}px`);
    }
  }, [optionList, refList]);

  const isSliderSmall = sliderSize === "small";

  return Style.it(
    `
        .HorizontalNav{
            border-bottom-width: ${fullBottomSeperatorWidth}; 
            border-bottom-color: ${fullBottomColor}; 
            padding-bottom: ${(bottomSeperatorHeight &&
              bottomSeperatorHeight) ||
              (isSliderSmall && 2)}px;
            font-size: ${(isNumber(fontSize) && fontSize + "px") || fontSize};
        }
        .HorizontalNav  .navigation  .navigationList  .navItem {
            border-right: ${!showSeperator && "none"};
            font-size: ${(isNumber(fontSize) && fontSize + "px") || fontSize};
        }
        .HorizontalNav  .navigation  .navigationList {
           padding: ${padding || "unset"} ;
        }
        .HorizontalNav  .navigation  .navigationList  h1 {
           color:${optionColor};
        }
      
        .HorizontalNav  .navigation  {
            margin-bottom: ${isSliderSmall && "-2px"};
        }
        .HorizontalNav  .navigation  .sliderTrack .slider {
          height: ${bottomSeperatorHeight || (isSliderSmall && 2)}px;
        }
        .HorizontalNav .navigation .sliderTrack{
            margin-top: ${bottomSeperatorTop};
        }

     `,
    <Box
      className={"HorizontalNav"}
      style={
        mini
          ? {
              marginTop: "0px"
            }
          : style
      }
      id="horizontalNav"
      ref={containerRef}
      {...props}
    >
      <Box className="navigation" width="inherit">
        <Box
          d="flex"
          alignItems="center"
          className="navigationList"
          id="navigation"
          width="inherit"
        >
          {optionList?.map((option, idx) => (
            <Box
              key={idx}
              className={
                target === idx && !option?.isStatic
                  ? "navItem navItem--active" // only non static option can be active
                  : "navItem"
              }
              style={navItemStyle}
              width="inherit"
              justifyContent="center"
              ref={!option?.isStatic ? refList[idx] : null} // static option cant have refs
              onClick={(e: any) => !option?.isStatic && optionFunc(e, idx)} // statis option cant be clicked
            >
              {option?.leftIcon && (
                <i style={{ marginRight: 10 }}>{option?.leftIcon}</i>
              )}
              <h1 style={{ color: optionColor }}>{option?.text}</h1>
              {option?.number > 0 && (
                option?.rowTag ??
                <RowTag
                  text={`${
                    option?.numberPrefix ? option?.numberPrefix : ""
                  }${millify(option?.number)}`}
                  width="25px !important"
                  height="25px !important"
                  borderRadius={"25% !important"}
                  marginLeft="8px"
                  appearance="pending"
                  // marginTop="-3px !important"
                  {...option?.numberContainerStyle}
                  textStyle={option?.numberTextStyle}
                />
              )}
              {option?.hasNew && (
                <Box marginLeft="8px">
                  <RowTag tag="new" />
                </Box>
              )}
            </Box>
          ))}
          {hiddenNavItems && hiddenNavItems?.length >= 1 && (
            <NavDropdown position="bottom center" listItems={hiddenNavItems} />
          )}
          {leftContent && <Box>{leftContent}</Box>}
        </Box>
        <Box className="sliderTrack">
          <Box className="slider" ref={sliderRef} style={dynamicStyles} />
        </Box>
      </Box>
      {children && <Box className="rightContent">{children}</Box>}
    </Box>
  );
};

export default withRouter(React.memo(HorizontalSliderNavigation));
