import {
  PaginationChangeOption,
  Table,
  TableProps
} from "@samedaycustom/core-ui";
import React, { useCallback } from "react";

export interface OrderListPage extends TableProps {
  row: any[];
  onSort?: (e: any) => void;
  sortKey?: { key: string; orderBy: string };
  EmptyView?: any;
  head?: any;
  totalRowCount: number;
  defaultNumRows?: number;
  pageNumber?: number;
  onPageChange?: ({
    number,
    options,
    callback
  }: {
    number?: number;
    options: PaginationChangeOption;
    callback: (result?: any) => void;
  }) => void;
  rowClickable?: boolean;
  loading?: boolean;
  isFirstPage?: boolean;
  isLastPage?: boolean;
  pageStart?: number;
  pageEnd?: number;
  fixedColumn?: number;
  fixedColumnEnd?: number;
  hidePagination?: boolean;
  showRecordsRange?: boolean;
  customRange?: { label: string; value: string }[];
  currentRecordRange?: string;
  handleRecordRange?: (range: string) => void;
}

/**
 *
 * page query identifier
 */
export default React.memo(
  ({
    row,
    onSort,
    sortKey,
    EmptyView,
    head,
    totalRowCount,
    onPageChange,
    defaultNumRows = 50,
    loading,
    rowClickable = true,
    pageNumber = 0,
    fixedColumn,
    fixedColumnEnd,
    hidePagination,
    ...props
  }: OrderListPage) => {
    const [paginationPage, setPaginationPage] = React.useState(pageNumber);
    const [numRows, setNumRows] = React.useState(defaultNumRows);

    const _setPage = useCallback(
      ({ number, options }) => {
        onPageChange &&
          onPageChange({
            number,
            options,
            callback: () => {
              setPaginationPage(options.currentPageNumber);
            }
          });
      },
      [paginationPage, row]
    );

    return (
      <Table
        fixedColumn={fixedColumn}
        fixedColumnEnd={fixedColumnEnd}
        head={head}
        rows={row}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={loading}
        isFixedSize
        emptyView={EmptyView}
        numRows={totalRowCount}
        rowsPerPage={numRows}
        setNumRows={setNumRows}
        stateless
        tdBorderBottom="1px solid #D9E2EC"
        defaultSortOrder={sortKey?.orderBy}
        sortOrder={sortKey?.orderBy}
        paginationPage={paginationPage}
        setPage={_setPage}
        headerBackgroundColor="#F0F4F8"
        onSort={onSort}
        onSetPage={() => console.log("onSetPage")}
        rowClickable={rowClickable}
        hidePagination={hidePagination}
        {...props}
      />
    );
  }
);
