import "./style.scss";

import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "../../_components";
import { css } from "@emotion/react";
import { Tooltip } from "@material-ui/core";
import { ReactComponent as Cog } from "@samedaycustom/assets/images/cog.svg";
import { ReactComponent as Link } from "@samedaycustom/assets/images/external-link-bold.svg";
import { ReactComponent as Info } from "@samedaycustom/assets/images/info-short.svg";
import { ReactComponent as NotesIcon } from "@samedaycustom/assets/images/note-icon.svg";
import { ReactComponent as Union } from "@samedaycustom/assets/images/union.svg";
import { CountdownTimer, CountWrapper, RowTag, TagType } from "@samedaycustom/core-ui";
import { parseDigits } from "@samedaycustom/helpers";
import { getModifiedDeliveryMethod } from "@samedaycustom/helpers/src/order";
import { ESDCDeliveryMethod, ESDCOrderSource, ESDCRepeatedCustomerType } from "@samedaycustom/sdc-types";
import { formatInTimeZone } from "date-fns-tz";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import millify from "millify";
import moment from "moment";
import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import Popup from "reactjs-popup";
import { ReactComponent as ChatDot } from "../assets/chat-dot.svg";
import { ReactComponent as ChatDouble } from "../assets/chat-double.svg";
import { ReactComponent as WebshopIcon } from "@samedaycustom/assets/images/webshop.svg";
import { ReactComponent as NetworkIcon } from "@samedaycustom/assets/images/network-internet.svg";
import { ReactComponent as RepeatedIcon } from "@samedaycustom/assets/images/repeated.svg";
import { ReactComponent as InfoIcon } from "packages/assets/images/reverseInfo.svg";
import { ReactComponent as UpdatedArtworkIcon } from "@samedaycustom/assets/images/updatedArtwork.svg";

export interface IOperationSideContent {
  id: string | React.ReactElement;
  name?: string | React.ReactElement;
  page?: "production" | "order" | "shipping";
  fetching?: boolean;
  orderTag: TagType;
  counter?: {
    days: string;
    hours: string;
    minutes: string;
  };
  orderDate?: number;
  dateFulfilled?: Date | number;
  dueDate?: number;
  nameLink?: string;
  price?: string | React.ReactElement;
  unpaidCharges?: string | React.ReactElement;
  deliveryMethod?: TagType;
  checkoutMessage?: string | React.ReactElement;
  isAdmin?: boolean;
  checkoutMessageOnMoreClick?: (e: any) => void;
  customerMessage?: {
    isNew?: boolean;
    count?: number;
    onClick?: (e: any) => void;
  };
  storeMessage?: {
    isNew?: boolean;
    count?: number;
    onClick?: (e: any) => void;
  };
  internalNote?: {
    isNew?: boolean;
    count?: number;
    onClick: (e: any) => void;
  };
  customerInternalNote?: {
    isNew?: boolean;
    count?: number;
    onClick: (e: any) => void;
  };
  redirectUrl?: string;
  totalQuantity?: number;
  color?: string;
  locationName: string;
  customerNumber: string;
  orderNumber?: string;
  isAccepted: boolean;
  isOrderAssigned?: boolean;
  isAcceptedAndActive?: boolean;
  originalDeliveryMethod?: TagType;
  onReassign: () => void;
  mobile: number | string;
  email: string;
  vendor: string | JSX.Element;
  shippingAddress: string | JSX.Element;
  settingOptions?: {
    onClick?: any;
    label: React.ReactText | string;
    style?: React.CSSProperties;
  }[];
  onOrderNumberClick?: () => void;
  onClickSetting?: () => void;
  onViewLog?: () => void;
  onChatWithCustomer?: () => void;
  onChatWithStore?: () => void;
  requestedBy?: string;
  hasRelatedOrders?: boolean;
  showHubspot?: boolean;
  timezone?: string;
  source?: ESDCOrderSource;
  isRepeated?: boolean;
  repeatedType?: ESDCRepeatedCustomerType;
  assignedAgents?: {
    agents: string | null;
    onClick: () => void;
  };
  hasPendingDueDateChangeRequest?: boolean;
  pendingDueDateChangeRequest?: string;
  hasUpdatedArtwork?: boolean;
  isFreeDelivery?: boolean;
  estimatedDeliveryDate?: string;
}

const repeatedColor = {
  default: "#E1FCF8",
  purple: "#E4DEFF",
  red: "#FEDAD9"
}


export default React.memo(
  ({
    id = "-",
    orderTag,
    page,
    checkoutMessage = "-",
    isAdmin,
    customerMessage,
    locationName,
    deliveryMethod,
    onReassign,
    internalNote,
    name = "-",
    onViewLog,
    dueDate,
    orderDate,
    price = "-",
    onChatWithCustomer,
    storeMessage,
    onChatWithStore,
    originalDeliveryMethod,
    checkoutMessageOnMoreClick,
    fetching,
    color,
    totalQuantity,
    orderNumber,
    customerInternalNote,
    customerNumber,
    onOrderNumberClick,
    isAccepted,
    mobile,
    email,
    vendor,
    settingOptions,
    isOrderAssigned,
    shippingAddress,
    dateFulfilled,
    redirectUrl,
    nameLink,
    showHubspot = false,
    hasRelatedOrders = false,
    timezone,
    source,
    isRepeated,
    repeatedType,
    assignedAgents,
    hasPendingDueDateChangeRequest,
    pendingDueDateChangeRequest,
    hasUpdatedArtwork,
    isFreeDelivery,
    estimatedDeliveryDate
  }: Partial<IOperationSideContent>) => {
    const MAX_LENGTH = 100;
    price = Number(price)?.toFixed(2)?.toString();
    orderTag = orderTag === "N.S" ? "R.S" : orderTag;

    const pageIdLink = page === "shipping" ? `${redirectUrl}/${id}` : null;
    const husbpotLink = `https://app.hubspot.com/contacts/8200736/contact/${email}`;
    const orderSource = source === ESDCOrderSource.WHITELABEL ? "Webshop" : "Network";
    const OrderSorceIcon = source === ESDCOrderSource.WHITELABEL ? WebshopIcon : NetworkIcon;

    const checkoutInfo = React.useMemo(
      () =>
        typeof checkoutMessage === "string" && checkoutMessage?.toString()?.length >= MAX_LENGTH ? (
          <Text style={{ color: "#334E68", maxWidth: "100%" }}>
            {checkoutMessage.substring(0, MAX_LENGTH).concat(" ... ")}

            <Popover isLazy placement="right" css={css({ marginLeft: "200px" })}>
              <PopoverTrigger>
                <span
                  style={{
                    color: "#007BFF",
                    textDecoration: "underline",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  onClick={checkoutMessageOnMoreClick}
                >
                  more
                </span>
              </PopoverTrigger>
              <PopoverContent top="-30px" left="60px">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Box position="relative">
                    <Box>
                      <Text color="#829AB1" fontSize="13px" fontFamily="Inter" fontWeight={500}>
                        Checkout Note
                      </Text>
                      <Text color="#102A43" fontSize="14px" style={{ maxWidth: "100%" }}>
                        {checkoutMessage}
                      </Text>
                    </Box>
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Text>
        ) : (
          checkoutMessage &&
          String(checkoutMessage?.toString()?.charAt(0)?.toUpperCase()).concat(
            checkoutMessage?.toString()?.toLocaleLowerCase()?.slice(1)
          )
        ),
      [checkoutMessage, checkoutMessageOnMoreClick]
    );

    const repeatedIconBgColor = useMemo(() => {
      
      if (isRepeated) {
        if (repeatedType === ESDCRepeatedCustomerType.WEBSHOP_TO_NETWORK) {
          return repeatedColor.purple;
        } else if (repeatedType === ESDCRepeatedCustomerType.NETWORK_TO_WEBSHOP) {
          return repeatedColor.red;
        } else {
          return repeatedColor.default;
        }
      }
      return repeatedColor.default;
    }, [isRepeated]);


    return (
      <Box
        position="relative"
        width="320px"
        background="#FFFFFF"
        boxShadow="0px 2px 4px rgba(159, 179, 200, 0.06), 0px 4px 6px rgba(159, 179, 200, 0.1)"
        borderRadius="16px"
        paddingTop="1.5rem"
        className="side_container"
        marginBottom="1rem"
        height="fit-content"
      >
        <Box className="row" d="inline-flex" justifyContent="space-between" width="100%">
          {nameLink ? (
            <NavLink to={nameLink}>
              <Flex alignItems='center'>
                <Text
                  color="#007BFF"
                  fontSize="14px"
                  fontFamily="Inter"
                  fontWeight={500}
                  textTransform="capitalize"
                  textDecor="underline"
                >
                  {(fetching && <Skeleton height={15} width={120} />) ||
                    name?.toString()?.toLocaleLowerCase() ||
                    "-"}
                </Text>
                {isRepeated && (
                  <Tooltip title="Repeated Customer" placement="top">
                    <Flex justifyContent='center' alignItems='center' marginLeft="5px" backgroundColor={repeatedIconBgColor} padding='2px' borderRadius='4px'>
                      <RepeatedIcon height="15px" width="15px" />
                    </Flex>
                  </Tooltip>
                )}
                {hasUpdatedArtwork && (
                <Tooltip title="Repeated Customer" placement="top">
                  <Flex justifyContent='center' alignItems='center' marginLeft="5px" backgroundColor='#C1F2C7' padding='2px' borderRadius='4px'>
                    <UpdatedArtworkIcon />
                  </Flex>
                </Tooltip>
              )}
              </Flex>
            </NavLink>
          ) : (
            <Flex alignItems='center'>
              <Text
                color="#627D98"
                fontSize="14px"
                fontFamily="Inter"
                fontWeight={500}
                textTransform="capitalize"
              >
                {(fetching && <Skeleton height={15} width={120} />) ||
                  name?.toString()?.toLocaleLowerCase() ||
                  "-"}
              </Text>
              {isRepeated && (
                <Tooltip title="Repeated Customer" placement="top">
                  <Flex justifyContent='center' alignItems='center' marginLeft="5px" backgroundColor={repeatedIconBgColor} padding='2px' borderRadius='4px'>
                    <RepeatedIcon height="15px" width="15px" />
                  </Flex>
                </Tooltip>
              )}
              {hasUpdatedArtwork && (
                <Tooltip title="Repeated Customer" placement="top">
                  <Flex justifyContent='center' alignItems='center' marginLeft="5px" backgroundColor='#C1F2C7' padding='2px' borderRadius='4px'>
                    <UpdatedArtworkIcon />
                  </Flex>
                </Tooltip>
              )}
            </Flex>
          )}

          {showHubspot && (
            <Box className="row" d="inline-flex">
              <a href={husbpotLink} target="_blank" rel="noopener noreferrer">
                <Text
                  color="#334E68"
                  textDecoration="underline"
                  fontSize="13px"
                  fontStyle="normal"
                  fontWeight={500}
                  cursor="pointer"
                  as="button"
                  d="inline-flex"
                  alignItems="center"
                  marginLeft="5px"
                >
                  Hubspot
                  <Link
                    height="12px"
                    width="12px"
                    style={{
                      marginLeft: "6px",
                    }}
                  />
                </Text>
              </a>
            </Box>
          )}

          {settingOptions && (
            <Popup
              on="click"
              trigger={() => <Cog style={{ cursor: "pointer" }} />}
              position="bottom center"
              closeOnDocumentClick={true}
              className="config__setting"
              arrow={false}
              contentStyle={{
                width: "unset",
                border: "none",
                padding: "0",
                height: "unset",
                borderRadius: "4px",
                left: "124px",
                boxShadow: "0px 5px 10px rgba(98, 125, 152, 0.1)",
              }}
            >
              <React.Fragment>
                {settingOptions?.map((o, i) => (
                  <Text
                    key={i}
                    onClick={o?.onClick}
                    style={o?.style}
                    className="optionListItem"
                    textTransform="capitalize"
                    color="#102a43"
                    fontSize="14px"
                    display="block"
                    width="172px"
                    padding="8px"
                    cursor="pointer"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {o?.label}
                  </Text>
                ))}
              </React.Fragment>
            </Popup>
          )}
          {onReassign && (
            <Tooltip title="Reassign Order" placement="top">
              <IconButton
                onClick={onReassign}
                background="#E5F1FF"
                aria-label="Reassign"
                title="Reassign"
                borderRadius="32px"
                width="32px"
                height="32px"
                d="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                icon={<Union />}
              />
            </Tooltip>
          )}
        </Box>

        <Flex className="row">
          <Text
            color={!pageIdLink ? "#102A43" : "#0062CC"}
            textDecoration={pageIdLink && "underline"}
            cursor={pageIdLink && "pointer"}
            fontFamily="Inter"
            fontWeight={500}
            fontSize="16px"
            paddingTop="0.1rem"
          >
            {(fetching && <Skeleton height={15} width={120} />) || (
              <span onClick={() => (pageIdLink ? onOrderNumberClick() : null)} role="link">
                {id}
              </span>
            ) ||
              "-"}
          </Text>
        </Flex>

        {orderTag && (
          <Box className="row" paddingTop="0.3rem" d="inline-flex">
            {(fetching && <Skeleton height={20} width={150} />) ||
              (orderTag && <RowTag tag={orderTag} isTag />) ||
              "-"}
            {typeof onViewLog === "function" && (
              <Text
                color="#334E68"
                textDecoration="underline"
                fontSize="13px"
                fontStyle="normal"
                fontWeight={500}
                cursor="pointer"
                as="button"
                onClick={onViewLog}
                d="inline-flex"
                alignItems="center"
                marginLeft="12px"
              >
                View log
                <Link
                  height="12px"
                  width="12px"
                  style={{
                    marginLeft: "6px",
                  }}
                />
              </Text>
            )}
          </Box>
        )}

        {hasRelatedOrders && (
          <Box className="row" paddingTop="0.3rem" d="inline-flex">
            <RowTag
              text="Related orders available"
              Icon={<Info fill="#31B237" height="15px" width="15px" />}
              backgroundColor="#E3F9E5"
              transformText={false}
              textStyle={{
                color: "#31B237",
                marginTop: "5px",
                fontSize: "13px",
              }}
            />
          </Box>
        )}

        <CountdownTimer data={{ id: id?.toString(), date: dueDate }}>
          {({ days, hours, minutes }) => {
            return (
              <Box
                color="#EF4E4E"
                d="flex"
                justifyContent="space-between"
                width="calc(320px - 100px)"
                paddingTop="1rem"
                className="row"
              >
                <CountWrapper number={days || "0"} unit="days" />
                <CountWrapper number={hours || "0"} unit="hrs" />
                <CountWrapper number={minutes || "0"} unit="mins" />
              </Box>
            );
          }}
        </CountdownTimer>

        {(assignedAgents) && (
          <Box className="row-content" paddingTop="20px !important">
            <Box className="col">
              <Text>Assigned agent</Text>
              {(fetching && <Skeleton height={15} width={120} />) || (<Box display={"flex"} gap={"4px"} fontWeight={"500"}>
                {assignedAgents.agents ? (<Text css={css`color: #07121D !important;`} whiteSpace={"nowrap"}> {assignedAgents.agents} </Text>) : null}
                <Text onClick={assignedAgents.onClick} cursor={"pointer"} css={css`color: #007BFF !important;`} textDecoration={"underline"}>{assignedAgents.agents ? "Edit" : "Assign agents"}</Text>
              </Box>
              )}
            </Box>
          </Box>
        )}
        {(mobile || email) && (
          <Box className="row-content" paddingTop="20px !important">
            <Box className="col">
              <Text>Phone Number</Text>
              <Text color="#334E68">
                {(fetching && <Skeleton height={15} width={120} />) ||
                  parsePhoneNumberFromString(parseDigits(mobile), "US")?.formatNational() ||
                  "-"}
              </Text>
            </Box>
            <Box className="col">
              <Text>Email address</Text>
              <Text color="#334E68" maxWidth="130px !important">
                {(fetching && <Skeleton height={15} width={120} />) || email || "-"}
              </Text>
            </Box>
          </Box>
        )}
        {customerNumber && (
          <Box className="row-content" paddingTop="20px !important">
            <Box className="col">
              <Text>Customer Number</Text>
              <Text color="#334E68">
                {(fetching && <Skeleton height={15} width={120} />) ||
                  parsePhoneNumberFromString(parseDigits(customerNumber), "US")?.formatNational() ||
                  "-"}
              </Text>
            </Box>
          </Box>
        )}

        {shippingAddress &&
          getModifiedDeliveryMethod(deliveryMethod) !== ESDCDeliveryMethod.PICKUP && (
            <Box className="row-content -x1">
              <Box className="col">
                <Text>Shipping Address</Text>
                <Text
                  color="#334E68"
                  maxWidth="100% !important"
                  fontWeight="normal !important"
                  textTransform="capitalize"
                >
                  {(fetching && <Skeleton height={15} width={120} />) || shippingAddress}
                </Text>
              </Box>
            </Box>
          )}
        {hasPendingDueDateChangeRequest && (<Box mb={"10px"} display={"flex"} backgroundColor={"#F7C948"} gap={"4px"} alignItems={"center"} py={"4px"} px={"3px"}>
          <InfoIcon />
          <Text fontSize={"12px"} fontWeight={"500"} lineHeight={"16px"}>Due date change <Text display={"inline"} fontWeight={"600"} textDecor={"underline"}>pending</Text> for {pendingDueDateChangeRequest}</Text>
        </Box>)}
        {(dueDate || orderDate) && (
          <Box className="row-content">
            <Box className="col">
              <Text>Order Date</Text>
              <Text color="#334E68">
                {(fetching && <Skeleton height={15} width={120} />) ||
                  (timezone
                    ? formatInTimeZone(orderDate, timezone, "dd MMM, yyyy")
                    : moment(orderDate).format("DD MMM, YYYY")) ||
                  "-"}
              </Text>
              <Text color="#334E68">
                {(fetching && <Skeleton height={15} width={120} />) ||
                  (timezone
                    ? formatInTimeZone(orderDate, timezone, "hh:mm a")
                    : moment(orderDate).format("hh:mm A")) ||
                  "-"}
              </Text>
            </Box>
            <Box className="col">
              <Text>Due Date</Text>
              <Text color="#334E68">
                {(fetching && <Skeleton height={15} width={120} />) ||
                  (timezone
                    ? formatInTimeZone(dueDate, timezone, "dd MMM, yyyy")
                    : moment(dueDate).format("DD MMM, YYYY")) ||
                  "-"}
              </Text>
              <Text color="#334E68">
                {(fetching && <Skeleton height={15} width={120} />) ||
                  (timezone
                    ? formatInTimeZone(dueDate, timezone, "hh:mm a")
                    : moment(dueDate).format("hh:mm A")) ||
                  "-"}
              </Text>
            </Box>
          </Box>
        )}
        {estimatedDeliveryDate  && getModifiedDeliveryMethod(deliveryMethod) !== ESDCDeliveryMethod.PICKUP && (
          <Box className="row-content -x1">
            <Box className="col">
              <Text>Delivery Due Date</Text>
              <Text color="#334E68">
                {(fetching && <Skeleton height={15} width={120} />) ||
                  (timezone
                    ? formatInTimeZone(estimatedDeliveryDate, timezone, "dd MMM, yyyy hh:mm a")
                    : moment(estimatedDeliveryDate).format("DD MMM, YYYY hh:mm A")) ||
                  "-"}
              </Text>
            </Box>
          </Box>
        )}
        {dateFulfilled && (
          <Box className="row-content -x1">
            <Box className="col">
              <Text>Order Fulfilled</Text>
              <Text color="#334E68">
                {(fetching && <Skeleton height={15} width={120} />) ||
                  (timezone
                    ? formatInTimeZone(dateFulfilled, timezone, "dd MMM, yyyy hh:mm a")
                    : moment(dateFulfilled).format("DD MMM, YYYY hh:mm A")) ||
                  "-"}
              </Text>
            </Box>
          </Box>
        )}
        <Box className="row-content">
          <Box className="col">
            {orderNumber && (
              <React.Fragment>
                <Text>Order No.</Text>
                {(fetching && <Skeleton height={15} width={120} />) || (
                  <Text
                    fontFamily="Inter"
                    fontWeight={500}
                    color={"#0062CC"}
                    textDecoration={"underline"}
                    cursor={"pointer"}
                    onClick={onOrderNumberClick}
                  >
                    {orderNumber}
                  </Text>
                )}
              </React.Fragment>
            )}
          </Box>
          <Box className="col">
            {
              totalQuantity && (
                <React.Fragment>
                  <Text>Total Quantity</Text>
                  <Text d="flex">
                    {(fetching && <Skeleton height={15} width={120} />) || <>{totalQuantity}</>}
                  </Text>
                </React.Fragment>
              )
              // ||
              // (false && (
              // 	<React.Fragment>
              // 		<Text>Unpaid Charges</Text>
              // 		<Text d="flex">
              // 			{(fetching && (
              // 				<Skeleton height={15} width={120} />
              // 			)) || (
              // 				<React.Fragment>
              // 					{unpaidCharges}
              // 					<Edit
              // 						style={{
              // 							marginLeft: "0.5rem",
              // 							cursor: "pointer"
              // 						}}
              // 						onClick={(e:any) =>
              // 							modal.form({
              // 								title:
              // 									"Extra Charges/Items",
              // 								okText: "Save",
              // 								okStyle: {
              // 									backgroundColor:
              // 										"#007BFF",
              // 									color: "#fff"
              // 								},
              // 								component: (
              // 									<EditCharges />
              // 								)
              // 							})
              // 						}
              // 					/>
              // 				</React.Fragment>
              // 			)}
              // 		</Text>
              // 	</React.Fragment>
              // ))
            }
          </Box>
        </Box>
        <Box className="row-content">
          <Box className="col">
            {deliveryMethod && (
              <>
                <Text>Delivery Method</Text>
                {(fetching && <Skeleton height={20} width={120} />) ||
                  (deliveryMethod && <RowTag tag={deliveryMethod} />) ||
                  "-"}
                {isFreeDelivery ? (<RowTag backgroundColor="#E3F9E5" text={"Free"} textStyle={{fontWeight: "500"}} height="24px" marginTop="4px"/>) : null}
              </>
            )}
          </Box>
          {locationName && (
            <Box className="col">
              <Text>Location</Text>
              <Text>{(fetching && <Skeleton height={15} width={120} />) || locationName}</Text>
            </Box>
          )}
          {vendor && (
            <Box className="col">
              <Text>Vendor</Text>
              <Text>{(fetching && <Skeleton height={15} width={120} />) || vendor}</Text>
            </Box>
          )}
        </Box>

        <Box className="row-content -x1">
          <Box className="col">
            <Text>Source</Text>
            <Flex width="100%">
              <Box paddingTop="3px" paddingRight="5px">
                <OrderSorceIcon width={"15px"} height={"15px"} />
              </Box>
              <Text
                title={orderSource}
                textOverflow={"ellipsis"}
                overflow={"hidden"}
                whiteSpace={"nowrap"}
              >
                {orderSource}
              </Text>
            </Flex>
          </Box>
        </Box>
        {/* 
        {[ESDCDeliveryMethod.PICKUP, ESDCDeliveryMethod.RUSH_PICKUP].includes(
          deliveryMethod as ESDCDeliveryMethod
        ) &&
          originalDeliveryMethod && ( */}
        {originalDeliveryMethod && !fetching && (<Box className="row-content -x1">
          <Box className="col">
            <Text>Original Delivery Method</Text>
            {
              <Text
                title={"Original Delivery Method"}
              >
                {(fetching && <Skeleton height={15} width={120} />) || originalDeliveryMethod}
              </Text>
            }
          </Box>
        </Box>)}
        {color && (
          <Box className="row-content">
            <Box className="col">
              <React.Fragment>
                <Text>Color</Text>
                {(fetching && <Skeleton height={20} width={120} />) ||
                  (color && (
                    <Text color="#334E68" fontSize="13px">
                      {color}
                    </Text>
                  )) ||
                  "-"}
              </React.Fragment>
            </Box>
          </Box>
        )}
        {checkoutInfo && !fetching && (
          <Box
            marginLeft="10px"
            marginRight="10px"
            marginBottom="20px"
            padding="1rem 1.5rem 1rem 0.8rem"
            background="#F7C948"
            borderRadius="0px 20px"
          >
            <Box className="col">
              <Text>Checkout Note</Text>
              <Box paddingTop="0.3rem">
                {(fetching && <Skeleton height={15} width={120} />) || checkoutInfo}
              </Box>
            </Box>
          </Box>
        )}
        {(customerMessage || internalNote) && !isAdmin && (
          <Box className="row" paddingTop="0.5rem" paddingBottom="2rem">
            <Box
              d="flex"
              color={"#007BFF"}
              cursor={"pointer"}
              onClick={customerMessage.onClick}
              width="fit-content"
              paddingBottom="1rem"
            >
              <ChatDouble fill={(isAccepted && "#007BFF") || "#829AB1"} />
              <Text fontFamily="Inter" fontWeight={500} paddingLeft="0.5rem" paddingRight="0.5rem">
                View Customer Messages
              </Text>

              {customerMessage?.isNew && <RowTag tag="new" />}
            </Box>
            <Box
              d="flex"
              color={"#007BFF"}
              cursor={"pointer"}
              onClick={internalNote?.onClick || null}
              width="fit-content"
              isTruncated
            >
              <ChatDot fill={"#007BFF"} />
              <Text fontFamily="Inter" fontWeight={500} paddingLeft="0.5rem" paddingRight="0.5rem">
                View Internal Notes
              </Text>
              {(internalNote?.isNew && <RowTag tag="new" />) ||
                (internalNote?.count && (
                  <RowTag
                    text={`+${millify(internalNote?.count || 0)}`}
                    width="fit-content"
                    height="fit-content"
                    color="#fff"
                    borderRadius="0px 4px"
                    backgroundColor="red"
                    textStyle={{
                      fontFamily: "Inter Bold",
                      fontSize: "10px",
                    }}
                  />
                ))}
            </Box>
          </Box>
        )}
        {(onChatWithCustomer || onChatWithStore) && isAdmin && (
          <Box className="row-content">
            <Box className="col">
              <Box
                d="flex"
                color={"#007BFF"}
                cursor={"pointer"}
                onClick={onChatWithCustomer}
                width="fit-content"
              >
                <ChatDouble fill={(isAccepted && "#007BFF") || "#829AB1"} />
                <Text
                  fontFamily="Inter"
                  fontWeight={500}
                  paddingLeft="0.5rem"
                  paddingRight="0.5rem"
                >
                  Customer
                </Text>

                {customerMessage?.isNew && <RowTag tag="new" />}
              </Box>
            </Box>
            {isOrderAssigned && (
              <Box className="col">
                <Box
                  d="flex"
                  color={"#007BFF"}
                  cursor={"pointer"}
                  onClick={onChatWithStore}
                  width="fit-content"
                >
                  <ChatDouble fill={(isAccepted && "#007BFF") || "#829AB1"} />
                  <Text
                    fontFamily="Inter"
                    fontWeight={500}
                    paddingLeft="0.5rem"
                    paddingRight="0.5rem"
                  >
                    Store
                  </Text>
                  {storeMessage?.isNew && <RowTag tag="new" />}
                </Box>
              </Box>
            )}
          </Box>
        )}
        {isAdmin && (
          <>
            <Box className="row-content" display="inline-flex !important">
              <Box className="col" paddingBottom="15px !important">
                <Box
                  d="flex"
                  color={"#007BFF"}
                  cursor={"pointer"}
                  onClick={internalNote?.onClick || null}
                  width="fit-content"
                >
                  <NotesIcon />
                  <Text
                    fontFamily="Inter"
                    fontWeight={500}
                    paddingLeft="0.5rem"
                    paddingRight="0.5rem"
                  >
                    Order Notes
                  </Text>

                  {(internalNote?.isNew && <RowTag tag="new" />) ||
                    (internalNote?.count > 0 && (
                      <RowTag
                        text={`+${millify(internalNote?.count || 0)}`}
                        width="fit-content"
                        height="fit-content"
                        color="#fff"
                        borderRadius="0px 4px"
                        backgroundColor="red"
                        textStyle={{
                          fontWeight: 600,
                          fontSize: "10px",
                        }}
                      />
                    ))}
                </Box>
              </Box>
            </Box>
            <Box className="row-content" display="inline-flex !important">
              <Box
                d="flex"
                color={"#007BFF"}
                cursor={"pointer"}
                onClick={customerInternalNote?.onClick || null}
                width="fit-content"
                paddingBottom="1rem"
              >
                <NotesIcon />
                <Text
                  fontFamily="Inter"
                  fontWeight={500}
                  paddingLeft="0.5rem"
                  paddingRight="0.5rem"
                  d="inline-flex"
                >
                  {isAdmin ? "Admin Internal Notes" : "Customer Notes"}&nbsp;
                  {(customerInternalNote?.isNew && <RowTag tag="new" />) ||
                    (customerInternalNote?.count > 0 && (
                      <RowTag
                        text={`+${millify(customerInternalNote?.count || 0)}`}
                        width="fit-content"
                        height="fit-content"
                        color="#243B53"
                        borderRadius="0px 4px"
                        backgroundColor="#D9E2EC"
                        textStyle={{
                          fontWeight: 600,
                          fontSize: "10px",
                        }}
                      />
                    ))}
                </Text>
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  }
);
