import {
  AnimatedSwitch,
  AuthedScreenLayout as Layout,
  Fallback,
  NotFound,
} from "@samedaycustom/core-ui";
import { useToast } from "@samedaycustom/hooks";
import NotificationContext from "admin/components/context/Notification";
import NavigationHeader from "admin/components/Header/NavigationHeader";
import SideBarChildren from "admin/components/SideBarChildren";
import * as api from "admin/helpers/constants/Api.constant";
import { getUserProfile } from "admin/providers/actions/user";
import {
  getVendorUnreadMesageCount,
  getCustomerUnreadMesageCount,
} from "admin/providers/features/message/async";
import * as screens from "admin/routes/screen";
import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { api as axios } from "../../providers/api";

const ViewBroadCast = lazy(() => import("admin/components/View/BroadCast"));
// const Dashboard = lazy(() => import("admin/pages/Dashboard"));
const Vendors = lazy(() => import("admin/pages/Vendors"));
const Locations = lazy(() => import("admin/pages/Locations"));
const Customers = lazy(() => import("admin/pages/Customers"));
const Payments = lazy(() => import("admin/pages/Payments"));
const Settings = lazy(() => import("admin/pages/Settings"));
const Deliveries = lazy(() => import("admin/pages/Labels"));
const Orders = lazy(() => import("admin/pages/Orders"));
const Message = lazy(() => import("admin/pages/Message"));
const ViewVendor = lazy(() => import("admin/pages/Vendors/ViewVendor"));
const ViewVendorProfile = lazy(() =>
  import("admin/pages/Vendors/ViewVendor/content/BusinessProfile")
);
const AbandonedCarts = lazy(() => import("admin/pages/AbandonedCarts"));
const EmailConfig = lazy(() => import("admin/pages/ConfigureEmail"));
const ViewCustomer = lazy(() => import("admin/pages/Customers/ViewCustomer"));
const ViewOrder = lazy(() => import("admin/pages/Orders/ViewOrder"));
const NotFoundComponent = () => <NotFound screens={screens as any} />;
const Brands = lazy(() => import("admin/pages/Brands"));
const AddBrand = lazy(() => import("admin/pages/Brands/AddBrand"));
const LocationPages = lazy(() => import("admin/pages/LocationPages"));
const Quotes = lazy(() => import("admin/pages/Quotes"));
const Templates = lazy(() => import("admin/pages/Templates"));


const Application = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  useEffect(() => {
    init();
    const getProfile = async () => await dispatch(getUserProfile());
    getProfile().catch(() =>
      toast({
        title: "Admin Portal:",
        description: "User profile retrival failed",
        position: "bottom-left",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    );
  }, []);

  function init() {
    dispatch(getVendorUnreadMesageCount());
    dispatch(getCustomerUnreadMesageCount());
    axios.interceptors.request.use(
      (conf) => {
        const tokenKey = localStorage.getItem("persist:root") as string;
        const auth = JSON.parse(tokenKey)?.auth;
        const token = JSON.parse(auth)?.token;
        conf.headers["Authorization"] = "Bearer " + token;
        return conf;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  return (
    <NotificationContext orderApi={api.ioApi}>
      <ViewBroadCast />
      <Layout
        renderHeader={(isOpen, setSideBarOpen) => (
          <NavigationHeader sideOpen={isOpen} openSidebar={setSideBarOpen} />
        )}
        renderSideBarChildren={SideBarChildren}
      >
        <Suspense fallback={<Fallback />}>
          <AnimatedSwitch>
            <Route exact path={"/"} component={Orders} />
            <Route exact path={screens.DASHBOARD} component={Orders} />
            <Route exact path={screens.MESSAGE} component={Message} />
            <Route exact path={screens.VENDORS} component={Vendors} />
            <Route exact path={screens.LOCATIONS} component={Locations} />
            <Route exact path={screens.CUSTOMERS} component={Customers} />
            <Route path={screens.PAYMENTS} component={Payments} />
            <Route exact path={screens.SETTINGS} component={Settings} />
            <Route exact path={screens.EMAIL_SETTINGS} component={EmailConfig} />
            <Route exact path={`${screens.ORDERS}`} component={Orders} />
            <Route path={`${screens.DELIVERIES}`} component={Deliveries} />
            <Route
              exact
              path={`${screens.VIEW_VENDOR_PROFILE}/:id`}
              component={ViewVendorProfile}
            />
            <Route path={`${screens.VIEW_VENDOR}/:id`} component={ViewVendor} />
            <Route path={`${screens.VIEW_CUSTOMER}/:id`} component={ViewCustomer} />
            <Route path={`${screens.VIEW_ORDER}/:id`} component={ViewOrder} />
            <Route path={screens.ABANDONED_CARTS} exact component={AbandonedCarts} />
            <Route path={screens.BRANDS} exact component={Brands} />
            <Route path={`${screens.BRANDS}/create`} exact component={AddBrand} />
            <Route path={`${screens.BRANDS}/edit/:domain`} exact component={AddBrand} />
            <Route path={screens.LOCATION_PAGES} exact component={LocationPages} />
            <Route path={screens.QUOTES} exact component={Quotes} />
            <Route path={screens.TEMPLATES} exact component={Templates} />
            <Route path={"*"} exact component={NotFoundComponent} />
          </AnimatedSwitch>
        </Suspense>
      </Layout>
    </NotificationContext>
  );
};

export default Application;
